import type { PureQueryOptions } from '@apollo/client';
import {
  faCheckCircle,
  faExternalLink,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button } from 'react-daisyui';
import type {
  MiniModelPartsFragment,
  Photo360PartsFragment,
  VideoPartsFragment,
} from '~/apollo/generated/schema';
import { Panel } from '~/components/common/Panel';
import { Tooltip } from '~/components/common/Tooltip';
import { DeleteUrlBasedSO } from '~/components/supportingObject/urlBasedSO/DeleteUrlBasedSO';

export function UrlBasedSOList({
  soType,
  items,
  refetchQueries,
}: {
  soType: 'MiniModel' | 'Photo360' | 'Video';
  items: Array<
    (MiniModelPartsFragment | Photo360PartsFragment | VideoPartsFragment) & {
      placement?: { id: number } | null;
    }
  >;
  refetchQueries?: PureQueryOptions[];
}) {
  return (
    <div className="space-y-4">
      {items.map(item => (
        <Panel key={item.id}>
          <Panel.Heading>
            <div className="flex justify-between gap-6 items-center w-full">
              <Panel.Title>
                {item.name}{' '}
                <span className="text-muted text-sm">{item.id}</span>
              </Panel.Title>

              <div className="space-x-2">
                {item.placement && (
                  <Badge color="success" className="text-green-800 gap-2">
                    <FontAwesomeIcon icon={faCheckCircle} /> Placed
                  </Badge>
                )}

                <DeleteUrlBasedSO
                  soType={soType}
                  id={item.id}
                  refetchQueries={refetchQueries}
                >
                  {(deleteItem, isDeleting) => (
                    <Tooltip message="Delete item and any related data (e.g. placements, camera position)">
                      <Button
                        type="button"
                        color="ghost"
                        size="xs"
                        onClick={deleteItem}
                        disabled={isDeleting}
                        loading={isDeleting}
                        startIcon={<FontAwesomeIcon icon={faTrash} />}
                      />
                    </Tooltip>
                  )}
                </DeleteUrlBasedSO>
              </div>
            </div>
          </Panel.Heading>

          <Panel.Body>
            <div className="grid lg:grid-cols-12 gap-x-6 gap-y-0">
              <div className="lg:col-span-2 text-muted">URL</div>
              <div className="lg:col-span-10 break-all">
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link"
                >
                  {item.url}{' '}
                  <FontAwesomeIcon
                    icon={faExternalLink}
                    className="text-sm text-slate-300"
                  />
                </a>
              </div>

              <div className="lg:col-span-2 text-muted">Collected</div>
              <div className="lg:col-span-10">
                {item.collectedBy}, {item.yearCollected}
              </div>

              <div className="lg:col-span-2 text-muted">Equipment</div>
              <div className="lg:col-span-10">{item.equipment}</div>
            </div>
          </Panel.Body>
        </Panel>
      ))}
    </div>
  );
}
