import type { PureQueryOptions } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import type {
  CreateVideoMutation,
  CreateVideoMutationVariables,
} from '~/apollo/generated/schema';
import { FormErrors } from '~/components/common/FormErrors';
import { UrlBasedSOFormFields } from '~/components/supportingObject/urlBasedSO/UrlBasedSOFormFields';
import type { UrlBasedSOFormValues } from '~/utils/modules/urlBasedSO';
import {
  initialUrlBasedSO,
  urlBasedSOToInput,
  urlBasedSOValidationSchema,
} from '~/utils/modules/urlBasedSO';

const CREATE_VIDEO = gql`
  mutation CreateVideo($video: UrlBasedSOInput!) {
    createVideo(video: $video) {
      id
    }
  }
`;

export function CreateVideoForm({
  outcropId,
  refetchQueries,
  onCreateSuccess,
}: {
  outcropId: number;
  refetchQueries: PureQueryOptions[];
  onCreateSuccess?: () => void;
}) {
  const [createVideo, { loading, error }] = useMutation<
    CreateVideoMutation,
    CreateVideoMutationVariables
  >(CREATE_VIDEO, { refetchQueries });

  async function handleSubmit(values: UrlBasedSOFormValues) {
    try {
      await createVideo({
        variables: { video: urlBasedSOToInput(outcropId, values) },
      });
      toast.success('Video created successfully.');
      if (onCreateSuccess) onCreateSuccess();
    } catch (err) {
      console.log('Error creating video', err);
      toast.error('There was a problem creating the video. Please try again.');
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialUrlBasedSO()}
      validationSchema={urlBasedSOValidationSchema}
    >
      <Form>
        <div className="space-y-4">
          <UrlBasedSOFormFields />
          <FormErrors graphQLError={error} />

          <div className="text-center">
            <Button color="primary" loading={loading} disabled={loading}>
              Save
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  );
}
