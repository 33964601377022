import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Disclosure } from '@headlessui/react';
import { cn } from '~/utils/common';
import React from 'react';
import { Link, NavLink, useMatches } from 'react-router-dom';
import { Role } from '~/apollo/generated/schema';
import { RoleSecured } from '~/components/auth/RoleSecured';
import { useAuth } from '~/contexts/auth';
import * as routes from '~/paths';
import { HamburgerMenu } from './HamburgerMenu';
import { LoginButton } from './LoginButton';
import { UserMenu } from './UserMenu';

type NavItem = { to: string; children: string };
const navItems: NavItem[] = [
  { to: routes.dataSearchRoute(), children: 'Data' },
  { to: routes.modernRoute(), children: 'Modern' },
  { to: routes.wikiRoute(), children: 'Wiki' },
  { to: routes.searchRoute(), children: 'Search' },
  { to: '/standard', children: 'Standard' },
  { to: routes.vftRoute(), children: 'VFT' },
];

const collapsedItems = (authed: boolean): NavItem[] => {
  const aboutRoute = { to: routes.aboutRoute('background'), children: 'About' };

  if (!authed) return [aboutRoute];
  return [
    aboutRoute,
    ...navItems,
    { to: routes.mySafariRoute(), children: 'My Safari' },
  ];
};

type MenuLinkProps = {
  to: string;
  children: React.ReactNode;
};
function MenuLink({ to, children }: MenuLinkProps) {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `px-3 py-3 rounded-md text-base text-zinc-300 hover:bg-zinc-500 hover:text-white ${
          isActive ? 'bg-zinc-700' : ''
        }`
      }
    >
      {children}
    </NavLink>
  );
}

type NavbarProps = {
  transparent?: boolean;
};

export function Navbar({ transparent = false }: NavbarProps) {
  const { authority } = useAuth();
  const isOnDashboard = useMatches().find(r => r.id === 'routes/dashboard');

  const homeLink =
    isOnDashboard || !authority ? routes.homeRoute() : routes.dashboardRoute();

  return (
    <Disclosure
      as="nav"
      className={cn(
        'text-zinc-300',
        transparent ? 'backdrop-blur-md shadow-sm' : 'bg-zinc-600',
      )}
    >
      {({ open }) => (
        <>
          <div className="container mx-auto max-w-7xl">
            <div className="relative flex h-20 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button
                  id="mobileMenuToggle"
                  className="inline-flex items-center justify-center rounded-md p-2 text-zinc-400 hover:bg-zinc-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                >
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <FontAwesomeIcon
                      icon={faXmark}
                      className="block h-6 w-6"
                      aria-hidden="true"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faBars}
                      className="block h-6 w-6"
                      aria-hidden="true"
                    />
                  )}
                </Disclosure.Button>
              </div>

              <div className="flex flex-1 items-center justify-center lg:items-stretch lg:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <Link to={homeLink} className="mb-2">
                    <img
                      className="h-10 w-auto"
                      src="/assets/images/common/safari-logo-white.png"
                      alt="SafariDB"
                    />
                  </Link>
                </div>

                <RoleSecured>
                  <div className="hidden lg:ml-6 lg:block">
                    <div className="flex space-x-1">
                      {navItems.map(item => (
                        <MenuLink key={item.to} to={item.to}>
                          {item.children}
                        </MenuLink>
                      ))}
                      <RoleSecured roles={[Role.RoleAdmin]}>
                        <MenuLink to={routes.tutorialsRoute()}>
                          Tutorials
                        </MenuLink>
                      </RoleSecured>
                    </div>
                  </div>
                </RoleSecured>
              </div>

              <div className="absolute inset-y-0 right-0 flex items-center pr-2 lg:static lg:inset-auto lg:ml-6 lg:pr-0">
                <div className="hidden lg:block lg:space-x-1">
                  <RoleSecured key="my-safari">
                    <MenuLink to={routes.collectionsRoute()}>
                      My Safari
                    </MenuLink>
                  </RoleSecured>
                  <RoleSecured roles={[Role.RoleAdmin, Role.RoleCompanyAdmin]}>
                    <MenuLink to={routes.adminRoute()}>Administration</MenuLink>
                  </RoleSecured>
                </div>

                <div className="mx-1">
                  {authority && <UserMenu name={authority.user.name} />}
                  {!authority && (
                    <div className="space-x-1">
                      <LoginButton className="px-3 py-3 rounded-md text-base text-zinc-300 hover:bg-zinc-500 hover:text-white" />
                      <MenuLink to="/register">Register</MenuLink>
                    </div>
                  )}
                </div>
              </div>

              <div className="hidden lg:block">
                <HamburgerMenu />
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="container mx-auto space-y-1 pt-2 pb-3">
              {collapsedItems(!!authority).map(item => (
                <Disclosure.Button
                  key={item.to}
                  as={Link}
                  to={item.to}
                  className="block px-3 py-2 rounded-md text-base font-medium text-zinc-300 hover:bg-zinc-500 hover:text-white"
                >
                  {item.children}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
