import type { PureQueryOptions } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import type {
  CreateMiniModelMutation,
  CreateMiniModelMutationVariables,
} from '~/apollo/generated/schema';
import { FormErrors } from '~/components/common/FormErrors';
import { UrlBasedSOFormFields } from '~/components/supportingObject/urlBasedSO/UrlBasedSOFormFields';
import type { UrlBasedSOFormValues } from '~/utils/modules/urlBasedSO';
import {
  initialUrlBasedSO,
  urlBasedSOToInput,
  urlBasedSOValidationSchema,
} from '~/utils/modules/urlBasedSO';

const CREATE_MINI_MODEL = gql`
  mutation CreateMiniModel($miniModel: UrlBasedSOInput!) {
    createMiniModel(miniModel: $miniModel) {
      id
    }
  }
`;

export function CreateMiniModelForm({
  outcropId,
  refetchQueries,
  onCreateSuccess,
}: {
  outcropId: number;
  refetchQueries: PureQueryOptions[];
  onCreateSuccess?: () => void;
}) {
  const [createMiniModel, { loading, error }] = useMutation<
    CreateMiniModelMutation,
    CreateMiniModelMutationVariables
  >(CREATE_MINI_MODEL, { refetchQueries });

  async function handleSubmit(values: UrlBasedSOFormValues) {
    try {
      await createMiniModel({
        variables: { miniModel: urlBasedSOToInput(outcropId, values) },
      });
      toast.success('Mini-model created successfully.');
      if (onCreateSuccess) onCreateSuccess();
    } catch (err) {
      console.log('Error creating mini-model', err);
      toast.error(
        'There was a problem creating the mini-model. Please try again.',
      );
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialUrlBasedSO()}
      validationSchema={urlBasedSOValidationSchema}
    >
      <Form>
        <div className="space-y-4">
          <UrlBasedSOFormFields />
          <FormErrors graphQLError={error} />

          <div className="text-center">
            <Button color="primary" loading={loading} disabled={loading}>
              Save
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  );
}
