import type { UrlBasedSoInput } from '~/apollo/generated/schema';
import { yup } from '~/utils/validation';

export type UrlBasedSOFormValues = {
  name: string;
  url: string;
  collectedBy: string;
  yearCollected: string;
  equipment: string;
};

export function initialUrlBasedSO() {
  return {
    name: '',
    url: '',
    collectedBy: '',
    yearCollected: '',
    equipment: '',
  };
}

export function urlBasedSOToInput(
  outcropId: number,
  fv: UrlBasedSOFormValues,
): UrlBasedSoInput {
  return {
    ...fv,
    yearCollected: parseInt(fv.yearCollected),
    outcropId,
  };
}

export const urlBasedSOValidationSchema = yup.object({
  name: yup.string().required(),
  url: yup.string().url().required(),
  collectedBy: yup.string().required(),
  yearCollected: yup.number().integer().positive().required(),
  equipment: yup.string().required(),
});
