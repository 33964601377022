import { Field } from 'formik';
import { FormikField } from '~/components/common/FormikField';

export function UrlBasedSOFormFields() {
  return (
    <div className="space-y-2">
      <Field name="name" label="Name" component={FormikField} required />
      <Field
        name="url"
        label="URL"
        component={FormikField}
        placeholder="https://sketchfab.com/..."
        required
      />

      <div className="grid lg:grid-cols-2 gap-6">
        <Field
          name="collectedBy"
          label="Collected by"
          component={FormikField}
          required
        />
        <Field
          name="yearCollected"
          label="Year collected"
          component={FormikField}
          placeholder={new Date().getFullYear()}
          required
        />
      </div>
      <Field
        name="equipment"
        label="Equipment"
        component={FormikField}
        required
      />
    </div>
  );
}
