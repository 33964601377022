import type { PureQueryOptions } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import * as R from 'ramda';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { miniModelParts } from '~/apollo/fragments';
import type {
  UploadOutcropMiniModelsPageQuery,
  UploadOutcropMiniModelsPageQueryVariables,
} from '~/apollo/generated/schema';
import { ExpandedIcon } from '~/components/common/icons/ExpandedIcon';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { CreateMiniModelForm } from '~/components/supportingObject/urlBasedSO/CreateMiniModelForm';
import { UrlBasedSOList } from '~/components/supportingObject/urlBasedSO/UrlBasedSOList';

const UPLOAD_OUTCROP_MINI_MODELS_PAGE = gql`
  query UploadOutcropMiniModelsPage($id: Int!) {
    outcropList(id: $id) {
      id
      miniModels {
        ...miniModelParts
        placement {
          id
        }
      }
    }
  }

  ${miniModelParts}
`;

export default function UploadOutcropMiniModelsPage() {
  const params = useParams();
  invariant(params.outcropId);
  const outcropId = parseInt(params.outcropId);

  const [isCreating, setIsCreating] = useState(false);

  const queryVars = { id: outcropId };

  const { data, loading } = useQuery<
    UploadOutcropMiniModelsPageQuery,
    UploadOutcropMiniModelsPageQueryVariables
  >(UPLOAD_OUTCROP_MINI_MODELS_PAGE, { variables: queryVars });

  const refetchQueries: [
    PureQueryOptions<UploadOutcropMiniModelsPageQueryVariables>,
  ] = [{ query: UPLOAD_OUTCROP_MINI_MODELS_PAGE, variables: queryVars }];

  const outcrop = data?.outcropList.find(oc => oc.id === outcropId);
  const miniModels = R.sortBy(mm => mm.name, outcrop?.miniModels ?? []);

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <div className="space-y-4">
      <Panel>
        <Panel.Heading>
          <button
            type="button"
            onClick={() => setIsCreating(!isCreating)}
            className="w-full flex justify-between gap-6 items-center"
          >
            <Panel.Title>Create Mini-Model</Panel.Title>
            <ExpandedIcon expanded={isCreating} />
          </button>
        </Panel.Heading>

        {isCreating && (
          <Panel.Body>
            <CreateMiniModelForm
              outcropId={outcropId}
              refetchQueries={refetchQueries}
              onCreateSuccess={() => setIsCreating(false)}
            />
          </Panel.Body>
        )}
      </Panel>

      {!miniModels.length && <p>No mini-models created yet.</p>}

      <UrlBasedSOList
        soType="MiniModel"
        items={miniModels}
        refetchQueries={refetchQueries}
      />
    </div>
  );
}
